import { Link } from "gatsby";
import React from "react";
import {
  Button, Container, Form,
  FormControl,
  InputGroup,
  Row
} from "react-bootstrap";
import Layout from "../components/Layout";

export default function InfluencerRegisterPage() {
  const [seconds, setSeconds] = React.useState(59);
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("00:00");
    }
  });
  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 pt-3 mx-auto text-center">
              <div className="px-xl-5 mx-5">
                <img src="../img/influencer-register.svg" alt="register" />
                <h5 className="fw-bold fs-26 my-4">Register as Influencer</h5>
                <p className="px-3">
                  We have sent you the code at +971 000 000 0000
                </p>
              </div>
              <Form className="mt-4">
                <div className="col-xl-12 col-md-12 register-form">
                  <Row className="nav-link justify-content-center d-flex">
                    <InputGroup className="mb-2 w-auto ms-0">
                      <FormControl
                        type="tel"
                        aria-label="Text input with dropdown button"
                        maxLength={1}
                      />
                    </InputGroup>

                    <InputGroup className="mb-2 w-auto ms-0">
                      <FormControl
                        type="tel"
                        aria-label="Text input with dropdown button"
                        maxLength={1}
                      />
                    </InputGroup>

                    <InputGroup className="mb-2 w-auto ms-0">
                      <FormControl
                        type="tel"
                        aria-label="Text input with dropdown button"
                        maxLength={1}
                      />
                    </InputGroup>

                    <InputGroup className="mb-2 w-auto ms-0">
                      <FormControl
                        type="tel"
                        aria-label="Text input with dropdown button"
                        maxLength={1}
                      />
                    </InputGroup>
                  </Row>
                </div>
                <div className="mb-4 text-start ms-xl-5 ms-lg-5 ms-3 ps-2">({seconds})</div>
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="py-xl-5">
          <section className="Light-bg my-5">
            <Row>
              <div className="col-xl-6 col-lg-5=6 col-md-12 ps-0 bg-last-section">
                <img src="../img/Onboarding.svg" alt="bg" />
              </div>
              <div className="col-xl-5 col-lg-6 col-md-12 pt-xl-5 pt-lg-5">
                <h4 className="fs-40 fw-bold mb-5">Are you a merchant?</h4>
                <h5 className="bogo-par fw-normal lh-32 mb-5 fw-airlight pe-5 me-5">
                  Increase foot traffic and sustainably grow your profitability
                  with The BOGO App’s Dynamic Campaign Management™ system
                </h5>
                <Link to="#" className="fs-18 link-color pb-2">
                  BOGO for Business{" "}
                 <img src="../img/arrow-right.svg" alt="arrow right" className="ms-3" />
                </Link>
              </div>
            </Row>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
